import React from 'react';
import { Box, Typography, Grid, IconButton, Link, useTheme } from '@mui/material';
import { LinkedIn, Facebook, GitHub } from '@mui/icons-material';

const Footer = () => {
    const navLinks = [
        { label: 'Qui suis-je ?', href: '#a-propos' },
        { label: 'Mon parcours', href: '#mon-parcours' },
        { label: 'Mes compétences', href: '#mes-competences' },
        { label: 'Mes projets', href: '#mes-projets' },
        { label: 'Contact', href: '#contact' },
    ];

    const socialLinks = [
        { icon: <LinkedIn fontSize="large" />, href: 'https://linkedin.com' },
        { icon: <Facebook fontSize="large" />, href: 'https://facebook.com' },
        { icon: <GitHub fontSize="large" />, href: 'https://github.com/' },
    ];
    const theme = useTheme();
    return (
        <Box sx={{ width: '100%', textAlign: 'center', padding:'60px 15px', bgcolor: 'background.paper' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Emile Rouilleaux</Typography>
            <Grid container justifyContent="center" spacing={2} sx={{ my: 2 }}>
                {navLinks.map((link, index) => (
                    <Grid item key={index}>
                        <Link href={link.href} color="inherit" sx={{ textDecoration: 'none' }}>
                            {link.label}
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Grid container justifyContent="center" spacing={2} sx={{ my: 2 }}>
                {socialLinks.map((link, index) => (
                    <Grid item key={index}>
                        <IconButton href={link.href} target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.primary.main}}>
                            {link.icon}
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
            <Typography variant="body2" sx={{ mt: 3, textAlign: 'center !important',  }}>&copy; {new Date().getFullYear()} Emile Rouilleaux, Tous droits réservés.</Typography>
        </Box>
    );
};

export default Footer;
