import React from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useTheme,
    Grid,
    Chip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PublicIcon from '@mui/icons-material/Public';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ComputerIcon from '@mui/icons-material/Computer';
import InsightsIcon from '@mui/icons-material/Insights';
import ProjectIcon from '@mui/icons-material/AccountTree'; // Exemple pour Gestion de projet
import DesignIcon from '@mui/icons-material/DesignServices'; // Exemple pour UX/UI Design
import MarketingIcon from '@mui/icons-material/TrendingUp'; // Exemple pour Marketing Digital
import CommunicationIcon from '@mui/icons-material/ConnectWithoutContact'; // Exemple pour Marketing Digital

// Exemple de logiciels et de niveau de maîtrise pour les compétences techniques
const technicalSkills = [
    { title: 'Gestion Projet - Agile', Icon: ProjectIcon, details: ['JIRA', 'Confluence', 'Scrum', 'Kanban'] },
    { title: 'UX/UI Design', Icon: DesignIcon, details: ['Figma', 'Adobe XD', 'Sketch'] },
    { title: 'Marketing Digital', Icon: MarketingIcon, details: ['Google Analytics', 'SEO/SEM', 'AdWords'] },
    { title: 'Community Management', Icon: CommunicationIcon, details: ['Hootsuite', 'Buffer', 'Engagement'] },
    { title: 'Intégration - CMS', Icon: ComputerIcon, details: ['WordPress', 'Drupal', 'Joomla', 'Prestashop'] },
    // Ajoutez ici d'autres compétences techniques
];

// Soft skills avec une courte description
const softSkills = [
    { title: 'Leadership', description: 'Diriger des équipes vers des objectifs communs', Icon: GroupWorkIcon },
    { title: 'Communication', description: 'Transmettre clairement les idées et les informations', Icon: PublicIcon },
    { title: 'Résolution de problèmes', description: 'Identifier et résoudre les problèmes de manière efficace', Icon: PsychologyIcon },
    { title: 'Gestion du temps', description: 'Prioriser les tâches et gérer efficacement le temps', Icon: ScheduleIcon },
    { title: 'Esprit d\'équipe', description: 'Collaborer et travailler en harmonie avec l\'équipe', Icon: ThumbUpIcon },
    // Ajoutez d'autres soft skills ici
];

const Skills = () => {
    const theme = useTheme();

    const AccordionSkill = ({ skill, details }) => (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <skill.Icon color="primary" />
                    </Grid>
                    <Grid item>
                        <Typography>{skill.title}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Typography paragraph>{skill.description}</Typography>
                {details && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {details.map((detail, index) => (
                            <Chip key={index} label={detail} variant="outlined" />
                        ))}
                    </Box>
                )}
            </AccordionDetails>
        </Accordion>
    );

    return (
        <Box
            id="mes-competences"
            sx={{
                width: '100%',
                height: { md: '100vh', xs: 'auto' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { lg: '0px 0px', md: '80px 0px', xs: '60px 15px' },
            }}
        >
            <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', mb: 4, color: theme.palette.primary.main }}>
                Mes Compétences
            </Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ maxWidth: '1280px' }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>Compétences Techniques</Typography>
                    {technicalSkills.map((skill, index) => (
                        <AccordionSkill key={index} skill={skill} details={skill.details} />
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h4" gutterBottom>Soft Skills</Typography>
                    {softSkills.map((skill, index) => (
                        <AccordionSkill key={index} skill={skill} />
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Skills;
