import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Switch, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Nav = ({ onThemeChange }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleThemeChange = (event) => {
        onThemeChange(event);
        setDrawerOpen(false);
    };

    const menuItems = [
        { text: 'Qui suis-je ?',link:'#a-propos' },
        { text: 'Mon parcours',link:'#mon-parcours' },
        { text: 'Mes compétences',link:'#mes-competences' },
        { text: 'Mes projets',link:'#mes-projets' },
        { text: 'Contact',link:'#contact' }
    ];

    return (
        <AppBar position="fixed" color="primary">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Emile Rouilleaux
                </Typography>
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={toggleDrawer}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer
                    anchor="top"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: '100%',
                            height: '100%',
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            onClick={toggleDrawer}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: theme.palette.text.primary,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <List>
                            {menuItems.map((item, index) => (
                                <ListItem  key={index} onClick={toggleDrawer}>
                                    <Button href={item.link} color="inherit" sx={{ textDecoration: 'none' }}><ListItemText primary={item.text} color="inherit" sx={{ textDecoration: 'none' }}/></Button>
                                </ListItem>
                            ))}
                            <ListItem>
                                <Typography>Mode Sombre</Typography>
                                <Switch onChange={handleThemeChange} />
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {menuItems.map((item, index) => (
                        <Button href={item.link} key={index} color="inherit">{item.text}</Button>
                    ))}
                    <Switch onChange={handleThemeChange} />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Nav;
