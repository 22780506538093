import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box, useTheme } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';

const steps = [
    {
        label: 'INTM Groupe',
        description: 'Chez INTM Groupe, j’ai eu l’occasion de suivre le Run et le Build d’application, ce qui m’a permis de développer mes compétences en gestion de projet informatique. J’ai également pris part à la création d’une plateforme collaborative, renforçant ainsi mes compétences en travail d’équipe et en communication.',
        timeFrame: 'Juillet 2022 - Septembre 2023',
    },
    {
        label: 'Ceos Tech & Markus',
        description: 'En tant que Community Manager, j’ai géré les comptes LinkedIn et Facebook, élaborant un planning éditorial et interagissant avec la communauté. Cette expérience a affiné mes compétences en marketing digital et en gestion de réseaux sociaux.',
        timeFrame: 'Début Janvier - Fin Février 2021',
    },
    {
        label: 'ADA',
        description: 'En tant qu’agent de location de véhicules, j’ai géré la clientèle et le parc de véhicules, ce qui a renforcé mes compétences en service client et en gestion opérationnelle.',
        timeFrame: 'Octobre 2019 - Juin 2020',
    },
    // Autres expériences ici
];

const CareerPath = () => {
    const theme = useTheme();

    return (
        <Box
        id="mon-parcours"
            sx={{
                width: '100%',
                height: { md: '100vh', xs: 'auto' },
                padding:{ lg:'0px 30px', md: '80px 30px', xs: '60px 15px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'background.paper'
            }}
        >
            <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
                Mon Parcours
            </Typography>
            <Stepper activeStep={steps.length} orientation="vertical" sx={{ maxWidth:'1280px' }}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={<Typography variant="caption">{step.timeFrame}</Typography>}
                            StepIconProps={{
                                sx: {
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
                                },
                            }}
                        >
                            <Typography variant="h6">{step.label}</Typography>
                        </StepLabel>
                        <Typography variant="body1">{step.description}</Typography>
                    </Step>
                ))}
            </Stepper>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', marginTop: theme.spacing(4), color: theme.palette.primary.main }}>
                Mes Diplômes
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                {/* Ici, ajoutez les 3 blocs pour les diplômes avec les icônes et les descriptions */}
                {/* Exemple pour un diplôme */}
                <Box sx={{ display: 'flex', alignItems: 'center', margin: theme.spacing(1) }}>
                    <SchoolIcon />
                    <Typography variant="body1" sx={{ marginLeft: theme.spacing(1) }}>Titre du Diplôme</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', margin: theme.spacing(1) }}>
                    <SchoolIcon />
                    <Typography variant="body1" sx={{ marginLeft: theme.spacing(1) }}>Titre du Diplôme</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', margin: theme.spacing(1) }}>
                    <SchoolIcon />
                    <Typography variant="body1" sx={{ marginLeft: theme.spacing(1) }}>Titre du Diplôme</Typography>
                </Box>
                {/* Répétez pour les autres diplômes */}
            </Box>
        </Box>
    );
};

export default CareerPath;
