import { createTheme } from '@mui/material/styles';

const getDesignTokens = (mode) => {
    const baseTheme = createTheme({
        palette: { mode },
    });

    return {
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                    primary: { main: '#556cd6' },
                    secondary: { main: '#19857b' },
                    background: { default: '#fff', paper: '#fff' },
                    text: { primary: '#333', secondary: '#555' },
                }
                : {
                    primary: { main: '#90caf9' },
                    secondary: { main: '#80cbc4' },
                    background: { default: '#121212', paper: '#1e1e1e' },
                    text: { primary: '#fff', secondary: '#aaa' },
                }),
        },
        typography: {
            fontFamily: '"Poppins", sans-serif',
            h1: {
                fontSize: '3rem',
                fontWeight: 700,
                lineHeight: 1.3,
                textTransform: 'uppercase',
                [baseTheme.breakpoints.down('sm')]: { fontSize: '1.8rem' },
                [baseTheme.breakpoints.down('md')]: { fontSize: '2rem' },
            },
            h2: {
                fontSize: '2.8rem',
                fontWeight: 700,
                lineHeight: 1.3,
                textTransform: 'uppercase',
                [baseTheme.breakpoints.down('sm')]: { fontSize: '2rem' },
                [baseTheme.breakpoints.down('md')]: { fontSize: '2rem' },
            },
            body1: {
                fontSize: '1.2rem',
                textAlign: 'justify',
                fontWeight: 400,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                },
            },
            body2: {
                fontSize: '1.1rem',
                textAlign: 'left',
                fontWeight: 400,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '1rem',
                },
            },
            animeHeader: {
                fontSize: '1.3rem',
                textAlign: 'justify',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '1.1rem',
                },
            },
            p: {
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '0.9rem',
                },
            },
            a: {
                fontSize: '1rem',
                fontWeight: 500,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '0.9rem',
                },
            },
            button: {
                textTransform: '',
                fontSize: '1rem',
                fontWeight: 600,
                lineHeight: 1.3,
                [baseTheme.breakpoints.down('sm')]: {
                    fontSize: '0.9rem',
                },
            },
            // ...autres styles typographiques avec des ajustements responsifs
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    contained: {
                        backgroundColor: mode === 'light' ? '#556cd6' : '#90caf9',
                        border: mode === 'light' ? '1px solid #556cd6' : '1px solid #90caf9',
                        fontSize: '1.2rem',
                        padding: '8px 16px',
                        color: '#fff',
                        '&:hover': { backgroundColor: mode === 'light' ? '#4054b2' : '#648dae',border: mode === 'light' ? '1px solid #4054b2' : '1px solid #648dae' },
                    },
                    outlined: {
                        border: mode === 'light' ? '1px solid #556cd6' : '1px solid #90caf9',
                        fontSize: '1.2rem',
                        padding: '8px 16px',
                        color: mode === 'light' ? '#556cd6' : '#90caf9',
                        '&:hover': { border: mode === 'light' ? '1px solid #4054b2' : '1px solid #648dae' },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: `
                        ::-webkit-scrollbar {
                            width: 15px;
                        }
                        ::-webkit-scrollbar-track {
                            background: ${mode === 'light' ? '#fff' : '#121212'};
                        }
                        ::-webkit-scrollbar-thumb {
                            background-color: ${mode === 'light' ? '#556cd6' : '#90caf9'};
                            border-radius: 10px;
                            border: 3px solid ${mode === 'light' ? '#fff' : '#121212'};
                        }
                        ::-webkit-scrollbar-thumb:hover {
                            background-color: ${mode === 'light' ? '#4054b2' : '#648dae'};
                        }
                    `,
            },
        },
    };
};

export const lightTheme = createTheme(getDesignTokens('light'));
export const darkTheme = createTheme(getDesignTokens('dark'));



// import { createTheme } from '@mui/material/styles';

// const getDesignTokens = (mode) => ({
//     palette: {
//         mode,
//         ...(mode === 'light'
//             ? {
//                 primary: { main: '#556cd6' },
//                 secondary: { main: '#19857b' },
//                 background: { default: '#fff', paper: '#fff' },
//                 text: { primary: '#333', secondary: '#555' },
//             }
//             : {
//                 primary: { main: '#90caf9' },
//                 secondary: { main: '#80cbc4' },
//                 background: { default: '#121212', paper: '#1e1e1e' },
//                 text: { primary: '#fff', secondary: '#aaa' },
//             }),
//     },
//     typography: (theme) => ({
//         fontFamily: '"Poppins", sans-serif',
//         h1: {
//             fontSize: '3rem',
//             fontWeight: 700,
//             lineHeight: 1.3,
//             textTransform: 'uppercase',
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '2.5rem',
//             },
//             [theme.breakpoints.down('md')]: {
//                 fontSize: '2.7rem',
//             },
//         },
//         h2: {
//             fontSize: '2.8rem',
//             fontWeight: 700,
//             lineHeight: 1.3,
//             textTransform: 'uppercase',
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '2.3rem',
//             },
//             [theme.breakpoints.down('md')]: {
//                 fontSize: '2.5rem',
//             },
//         },
//         body1: {
//             fontSize: '1.2rem',
//             textAlign: 'justify',
//             fontWeight: 400,
//             lineHeight: 1.3,
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '1rem',
//             },
//         },
//         animeHeader: {
//             fontSize: '1.3rem',
//             textAlign: 'justify',
//             fontStyle: 'italic',
//             fontWeight: 400,
//             lineHeight: 1.3,
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '1.1rem',
//             },
//         },
//         p: {
//             fontSize: '1rem',
//             fontWeight: 500,
//             lineHeight: 1.3,
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '0.9rem',
//             },
//         },
//         a: {
//             fontSize: '1rem',
//             fontWeight: 500,
//             lineHeight: 1.3,
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '0.9rem',
//             },
//         },
//         button: {
//             textTransform: 'lowercase',
//             fontSize: '1rem',
//             fontWeight: 600,
//             lineHeight: 1.3,
//             [theme.breakpoints.down('sm')]: {
//                 fontSize: '0.9rem',
//             },
//         },
//         // ... Ajoutez ici d'autres styles typographiques si nécessaire
//     }),
//     components: {
//         MuiButton: {
//             styleOverrides: {
//                 contained: {
//                     backgroundColor: mode === 'light' ? '#556cd6' : '#90caf9',
//                     fontSize: '1.2rem',
//                     padding: '8px 16px',
//                     color: '#fff',
//                     '&:hover': { backgroundColor: mode === 'light' ? '#4054b2' : '#648dae' },
//                 },
//             },
//         },
//         MuiCssBaseline: {
//             styleOverrides: `
//         ::-webkit-scrollbar {
//           width: 15px;
//         }
//         ::-webkit-scrollbar-track {
//           background: ${mode === 'light' ? '#fff' : '#121212'};
//         }
//         ::-webkit-scrollbar-thumb {
//           background-color: ${mode === 'light' ? '#556cd6' : '#90caf9'};
//           border-radius: 10px;
//           border: 3px solid ${mode === 'light' ? '#fff' : '#121212'};
//         }
//         ::-webkit-scrollbar-thumb:hover {
//           background-color: ${mode === 'light' ? '#4054b2' : '#648dae'};
//         }
//       `,
//         },
//     },
// });

// export const lightTheme = createTheme(getDesignTokens('light'));
// export const darkTheme = createTheme(getDesignTokens('dark'));
