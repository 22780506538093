import React from 'react';
import { SpeedDial, SpeedDialAction, useTheme } from '@mui/material';
import { LinkedIn, Facebook, GitHub, Add, Share, FileDownload } from '@mui/icons-material';
import CV from'../assets/img/CV_Emile_Rouilleaux.pdf';

const SocialSpeedDial = () => {
    const theme = useTheme();

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Titre du site web',
                text: 'Découvrez mon site web !',
                url: window.location.href,
            })
            .then(() => console.log('Contenu partagé avec succès !'))
            .catch((error) => console.log('Erreur lors du partage :', error));
        } else {
            // Fallback pour les navigateurs qui ne supportent pas l'API Web Share
            console.log('API Web Share non prise en charge');
        }
    };
    // const handleDownloadCV = () => {
    //     const link = document.createElement('a');
    //     link.href = '../assets/img/CV_Emile_Rouilleaux.pdf';
    //     link.download = 'CV_Emile_Rouilleaux.pdf';
    //     link.click();
    // };

    const socialLinks = [
        { icon: <LinkedIn />, name: 'LinkedIn', url: 'https://www.linkedin.com/in/emile-rouilleaux/' },
        { icon: <Facebook />, name: 'Facebook', url: 'https://facebook.com' },
        { icon: <GitHub />, name: 'GitHub', url: 'https://github.com/' },
        { icon: <Share />, name: 'Partager', action: handleShare },
        { icon: <FileDownload />, name: 'Télécharger CV', url: CV },
    ];

    return (
        <SpeedDial
            ariaLabel="SpeedDial social media"
            sx={{ position: 'fixed', bottom: theme.spacing(3), right: theme.spacing(3) }}
            icon={<Add />} // Icône "+"
        >
            {socialLinks.map((action, index) => (
                <SpeedDialAction
                    key={index}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => action.url ? window.open(action.url, '_blank') : action.action()}
                />
            ))}
        </SpeedDial>
    );
};

export default SocialSpeedDial;
