import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, IconButton, useTheme } from '@mui/material';
import { LinkedIn, Facebook, GitHub } from '@mui/icons-material';
import profile from '../assets/img/profile.jpg';
import CV from'../assets/img/CV_Emile_Rouilleaux.pdf';

const Header = () => {
    const [currentRole, setCurrentRole] = useState(0);
    const [currentText, setCurrentText] = useState('');
    const roles = ['Chef de Projet IT', 'Community Manager', 'Développeur CMS (WordPress, Prestashop)'];
    const theme = useTheme();
    useEffect(() => {
        const interval = setInterval(() => {
            const role = roles[currentRole];
            let displayText = '';
            let i = 0;

            const typingInterval = setInterval(() => {
                displayText += role[i++];
                setCurrentText(displayText);
                if (i >= role.length) clearInterval(typingInterval);
            }, 50); // Vitesse de frappe

            setCurrentRole((prevRole) => (prevRole + 1) % roles.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [currentRole]);

    return (
        <Box
            sx={{
                height: { md: '100vh', xs: 'auto' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '80px',
                paddingBottom: '80px',
            }}
            id="a-propos"
        >
            <Box sx={{ position: 'absolute', left: theme.spacing(2), top: '50%', transform: 'translateY(-50%)', display: { lg: 'flex', md: 'none', xs: 'none' }, flexDirection: 'column', alignItems: 'center' }}>
                <IconButton href="https://www.linkedin.com/in/emile-rouilleaux/" target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.primary.main, fontSize: '2rem' }}>
                    <LinkedIn fontSize="large" />
                </IconButton>
                <IconButton href="https://facebook.com" target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.primary.main, fontSize: '2rem' }}>
                    <Facebook fontSize="large" />
                </IconButton>
                <IconButton href="https://github.com/" target="_blank" rel="noopener noreferrer" sx={{ color: theme.palette.primary.main, fontSize: '2rem' }}>
                    <GitHub fontSize="large" />
                </IconButton>
                <Box sx={{ width: '2px', height: '100px', bgcolor: theme.palette.primary.main, marginTop: theme.spacing(1) }} />
            </Box>
            <Container maxWidth="lg" sx={{
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                alignContent: 'center',
                justifyContent: 'center',
                bgcolor: { lg: 'background.paper', md: 'none', xs: 'none' },
                borderRadius: '24px',
                boxShadow: { lg: '0px 0px 10px 0px rgba(000,000,000,0.2)', md: 'none', xs: 'none' },
                padding: '24px',
            }}>
                <Box
                    sx={{
                        flex: 1,
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                    }}

                >
                    <Typography variant="h1" sx={{ color: theme.palette.primary.main, mb: 2 }}>Emile Rouilleaux</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Passionné par la transformation digitale et la gestion de projet, je suis Émile Rouilleaux, un professionnel dynamique et polyvalent du domaine digital. Actuellement en recherche active d'une alternance, mon objectif est de devenir chef de projet, où je pourrai apporter ma vision stratégique et mon expertise en marketing digital. Avec une expérience significative en community management et en développement de plateformes collaboratives, je suis prêt à relever de nouveaux défis et à contribuer efficacement à des projets innovants dans le digital.
                    </Typography>
                    <Typography
                        variant="animeHeader"
                        sx={{ mb: 2, position: 'relative', overflow: 'hidden' }}
                    >
                        {currentText}
                    </Typography>
                    <Box sx={{display: 'flex',justifyContent: { lg: 'left', md: 'center', xs: 'center' },alignContent: 'center', flexWrap:'wrap', gap:'12px'}}>
                        <Button href="#contact" variant="contained" sx={{ width: 'fit-content' }}>Prenons contact !</Button>
                        <Button href={CV} target="_blank" variant="outlined" sx={{ width: 'fit-content' }}>Télécharger mon CV</Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={profile}// Remplacez par l'URL de l'image réelle
                        alt="Emile Rouilleaux"
                        sx={{
                            flex: 1,
                            p: 2,
                            transition: 'transform 0.5s ease-in-out',
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            maxWidth: '450px',
                            width: '100%',
                            borderRadius: '24px',
                            '&:hover': {
                                transform: 'scale(1.02)',
                            },
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default Header;
