import React, { useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    useTheme,
    Grid,
    Link
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { MailOutline, Phone } from '@mui/icons-material';


const Contact = () => {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        rgpdConsent: false,
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'rgpdConsent' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logique d'envoi du formulaire
        console.log(formData);
    };

    return (
        <Box
        id="contact"
            sx={{
                width: '100%',
                height: { md: '100vh', xs: 'auto' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: { lg: 'rgba(000,000,000,0)', md: 'rgba(000,000,000,0)', xs: 'rgba(000,000,000,0)' },
                padding:{ lg:'0px 0px', md: '80px 0px', xs: '60px 15px' },
            }}
        >
            <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', color: theme.palette.primary.main }}>
                Contactez-moi
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center'

                }}
            >


                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '24px',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box sx={{ flex: 1, minWidth: '300px', display: 'flex', flexDirection: 'column', gap: '24px', justifyContent: 'center', alignItems: 'center'  }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            marginBottom: theme.spacing(1),
                            bgcolor: 'background.paper',
                            boxShadow: '0px 0px 10px 0px rgba(000,000,000,0.2)',
                            borderRadius: '10px',
                            padding: '30px 0',
                            gap: '12px',
                            width:'fit-content',
                            minWidth:'300px'
                        }}>
                            <MailOutline fontSize="large" sx={{ color: theme.palette.primary.main, }} />
                            <Typography variant="body1" sx={{ color: theme.palette.text,textDecoration: 'none' }}>
                            <Link color="inherit" href="mailto:emilerouilleaux@gmail.com" sx={{textDecoration: 'none' }}>emilerouilleaux@gmail.com</Link>
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            marginBottom: theme.spacing(1),
                            bgcolor: 'background.paper',
                            boxShadow: '0px 0px 10px 0px rgba(000,000,000,0.2)',
                            borderRadius: '10px',
                            padding: '30px 0',
                            gap: '12px',
                            width:'fit-content',
                            minWidth:'300px'
                        }}>
                            <Phone fontSize="large" sx={{ color: theme.palette.primary.main, }} />
                            <Typography variant="body1">
                            <Link href="tel:+330651705421"color="inherit"sx={{textDecoration: 'none' }} >+33 06 51 70 54 21</Link>
                            </Typography>
                        </Box>
                    </Box>


                    <Box component="form" onSubmit={handleSubmit}
                        sx={{
                            maxWidth: 650,
                            width: '100%',
                            padding: '24px',
                            borderRadius: '10px',
                            bgcolor: 'background.paper',
                            boxShadow: '0px 0px 10px 0px rgba(000,000,000,0.2)',
                        }}>
                        <Typography variant="h5" gutterBottom sx={{ textAlign: 'left', color: theme.palette.primary.main }}>
                            Formulaire de contact
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Nom Prénom"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Adresse Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Sujet"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <TextareaAutosize minRows={3} name="message" value={formData.message} onChange={handleChange} style={{ width: '100%', padding: '18.5px 14px', fontSize: '1rem', fontFamily: theme.typography.fontFamily, borderRadius: theme.shape.borderRadius, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary, border: `1px solid ${theme.palette.text.secondary}`, '&:focus-visible': { borderColor: theme.palette.primary.main, outline: 'none' } }} placeholder="Votre message..." />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="rgpdConsent"
                                            checked={formData.rgpdConsent}
                                            onChange={handleChange}
                                        />
                                    }
                                    label={
                                        <Typography variant="body2" sx={{ verticalAlign: 'middle' }}>
                                            J’atteste que les données renseignées sont exactes et j’accepte que ces données soient utilisées pour que Emile Rouilleaux puisse me contacter*
                                        </Typography>
                                    }
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '0.875rem', // Taille du texte du label
                                        },
                                        alignItems: 'start', // Alignement vertical de la checkbox
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Button variant="contained" type="submit">
                                    Envoyer
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

export default Contact;
