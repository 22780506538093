import React, { useState } from 'react';
import {
    Box,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    useTheme,
    Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const projects = [
    {
        id: 1,
        image: 'https://images.pexels.com/photos/163064/play-stone-network-networked-interactive-163064.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', // Image fictive
        image2: 'https://mateodelforge.com/Emile/img/sharepoint_figma.png', // Image fictive
        title2: 'Maquette Figma :',
        image3: 'https://mateodelforge.com/Emile/img/SharePoint_CDS.png', // Image fictive
        title3: 'Gantt :',
        title: 'Plateforme collaborative',
        shortDescription: `J'ai conçu une stratégie de marketing digital pour promouvoir avec succès notre espace collaboratif SharePoint, reconnaissant l'importance de l'engagement des utilisateurs.`,
        longDescription: `Dans le cadre du projet Sharepoint visant à mettre en place un espace collaboratif pour des collaborateurs, j'ai pris l'initiative d’élaborer une stratégie de marketing digital. Comme j'étais seul sur le projet et qu'il n'y avait pas de service dédié au marketing, j'ai rapidement compris l'importance de communiquer et promouvoir cet espace collaboratif auprès des collaborateurs. En effet, mettre en ligne un outil de collaboration sans le faire connaître et susciter l'intérêt des utilisateurs aurait limité son efficacité.`,
        tools: ['HTML', 'CSS', 'JavaScript'],
        projectLink: 'http://lien-vers-projet1.com',
    },
    {
        id: 2,
        image: 'https://images.pexels.com/photos/270348/pexels-photo-270348.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', // Image fictive
        title2: 'Maquette Figma :',
        image2: 'https://mateodelforge.com/Emile/img/Site-Gilloteaux.png', // Image fictive
        title3: 'Gantt :',
        image3: 'https://via.placeholder.com/345x140', // Image fictive
        title: 'Dev HTML CSS JS ',
        shortDescription: `J'ai développé un site vitrine pour un garage, combinant gestion de projet et compétences web, renforçant ainsi la présence en ligne.`,
        longDescription: `En tant que chef de projet digital, j'ai dirigé le développement d'un site vitrine pour un garage automobile, un projet qui a marqué ma première incursion dans le monde du développement web. J'ai acquis des compétences essentielles en HTML, CSS et Javascript, créant un site à la fois professionnel et interactif. Ce défi m'a permis de renforcer la présence en ligne du garage, tout en me dotant d'une expérience précieuse en gestion de projet et en développement technique, jetant ainsi les bases solides de ma carrière dans le domaine digital.`,
        tools: ['HTML', 'CSS', 'JavaScript'],
        projectLink: 'http://lien-vers-projet1.com',
    },
    {
        id: 3,
        image: 'https://images.pexels.com/photos/905163/pexels-photo-905163.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1', // Image fictive
        image2: 'https://mateodelforge.com/Emile/img/Community_management.png', // Image fictive
        image3: '', // Image fictive
        title: 'Community management',
        shortDescription: 'Mon stage chez Ceos Tech a été une expérience enrichissante, revitalisant leur présence en ligne et développant mes compétences en community management et motion design.',
        longDescription: `Au sein de Ceos Tech, une agence de consulting aidant les entrepreneurs et PME dans leurs projets digitaux, j'ai réalisé un stage de 7 semaines axé sur le community management pour Ceos Tech et Markus, leur application de gestion pour restaurateurs. Ma mission consistait à raviver leur présence sur les réseaux sociaux, un défi notable étant donné l'inactivité prolongée et une communauté limitée. J'ai mené un audit social media, établi des stratégies d'interaction et d'acquisition distinctes pour chaque marque, et surveillé la concurrence pour s'inspirer de leurs meilleures pratiques.

        Une partie importante de ma mission a été la création d'une vidéo en motion design, initialement avec After Effects puis adaptée avec Canva Pro pour répondre aux contraintes techniques. Cette expérience a non seulement affiné mes compétences en design social media et motion design, mais a aussi développé mon adaptabilité. Ce stage m'a conforté dans mon choix de carrière, renforçant ma confiance, ma capacité à résoudre des problèmes pratiques, et stimulant mon désir de me perfectionner davantage dans le design et le web. Il a souligné l'importance de la formation continue pour exceller dans les domaines de ma spécialisation.`,
        tools: ['HTML', 'CSS', 'JavaScript'],
        projectLink: 'http://lien-vers-projet1.com',
    },
    // Ajoutez d'autres projets ici
];

const ProjectCard = ({ project }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={project.image}
                    alt={project.title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {project.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {project.shortDescription}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen}>En savoir plus</Button>
                </CardActions>
            </Card>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="project-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="project-dialog-title">
                    {project.title}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: theme.spacing(1),
                            top: theme.spacing(1),
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {project.longDescription}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mb: 2 }}>
                        {project.tools.map((tool, index) => (
                            <Chip key={index} label={tool} variant="outlined" />
                        ))}
                    </Box>
                    <Typography gutterBottom>
                        {project.title2}
                    </Typography>
                    <CardMedia
                        component="img"
                        image={project.image2}
                        alt={project.title}
                    />
                    <Typography gutterBottom>
                        {project.title3}
                    </Typography>
                    <CardMedia
                        component="img"
                        image={project.image3}
                        alt={project.title}
                    />
                    {project.projectLink && (
                        <Button href={project.projectLink} target="_blank" rel="noopener">
                            Voir le projet
                        </Button>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};

const Projects = () => {
    const theme = useTheme();
    return (
        <Box sx={{ flexGrow: 1, padding: '60px 0px ', minHeight: '100vh', width: '100%', bgcolor: 'background.paper', display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }} id="mes-projets">
            <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', mb: 4, color: theme.palette.primary.main }}>
                Mes Réalisations
            </Typography>
            <Grid spacing={2} justifyContent="center" alignContent="center" sx={{ display: 'flex', width: '100%', gap: '48px', flexWrap: 'wrap' }}>
                {projects.map((project) => (
                    <Grid item xs={12} sm={6} md={4} key={project.id}>
                        <ProjectCard project={project} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Projects;
