import React, { useState } from 'react';
import { ThemeProvider} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './Theme/theme.js';
import Nav from "./components/nav";
import Header from "./components/header";
import CareerPath from './components/CareerPath.js';
import Skills from './components/Skills.js';
import Projects from './components/ProjectCard.js';
import Contact from './components/Contact.js';
import SocialSpeedDial from './components/SocialSpeedDial';
import Footer from './components/Footer.js';


const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const theme = darkMode ? darkTheme : lightTheme;


  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {/* <BackgroundBlobs /> */}
      <Nav onThemeChange={handleThemeChange} />
      <Header />
      <CareerPath />
      <Skills />
      <Projects />
      <Contact />
      <SocialSpeedDial />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
